body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-primary {
  background: var(--primary-color) !important;
}

.bg-secondary {
  background: var(--secondary-color) !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.white-color {
  color: var(--white-color) !important;
}

.carousel-indicators [data-bs-target] {
  border-radius: 9px;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.accordion-body > p {
  font-size: 21px !important;
  font-weight: 600 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  box-shadow: none !important;
  outline: 0 none;
}

.accordion {
  border-radius: 10px;
  border: 1px solid var(--navbar-bg-color);
}

.accordion-item {
  border-color: var(--navbar-bg-color) !important;
}

.accordion-item:last-child, .accordion-item:last-child:not(.collapsed), .accordion-item:last-child > h2, .accordion-item:last-child > h2 > button.collapsed, .accordion-item:last-child > div, .accordion-item:last-child > div > .accordion-body {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.accordion-item:last-child > h2 > button {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.accordion-item:first-child, .accordion-item:first-child > h2, .accordion-item:first-child > h2 > button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

#aboutUsContent, #coursesContent {
  padding-top: 50px;
}

#coursesContent > .card {
  border-radius: 20px !important;
}

p.speakers-title {
  font-size: 12px;
  font-weight: 700;
  color: var(--bs-gray-900);
}

.unifil-logo {
  background: white;
  border-radius: 35px;
}

.site-footer {
  background-color: var(--navbar-bg-color);
}

.bi-whatsapp.floating-contact {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 94px;
  color: #00a884;
  background: #cfcfcf47;
  padding: 8px 17px;
  font-size: 35px;
  border-radius: 45px;
}

.floating-rollup {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 182px;
  color: var(--primary-color);
  background: #cfcfcf47;
  padding: 8px 17px;
  font-size: 35px;
  border-radius: 45px;
}

.floating-rollup:hover {
  background: var(--primary-color);
  color: white;
}

.bi-whatsapp.floating-contact:hover {
  background: #00a884;
  color: white;
}

.speakers-thumb {
  cursor: pointer;
}

.construction {
  display: flex;
  justify-content: center;
}
.construction img {
  max-width: 1004px;
  width: 100%;
  max-height: 100vh;
}

.toast-message {
  width: 100%!important;
}